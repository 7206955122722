<template>
	<CModal :title="'History #'+details.booking_no" :show.sync="showModal" color="info" size="lg"> 
    	<div class="pl-3 pr-3 pb-3"> 
    		<CRow class="mb-2">
        		<CCol lg="12">
					<div class="table-responsive-sm">
        			<CDataTable
				      	:items="details.booking_history"
				      	:fields="[
							{
								key: 'status', 
								label: 'Description'
							},
							{
								key: 'users_id', 
								label: 'User'
							},
							{
								key: 'created_at', 
								label: 'Transaction Date'
							},
							{
								key: 'remarks', 
								label: 'Remarks'
							}
						]"
						hover
						small
						fixed
						borderless
						
						
				    >   
				    	<template #status="{item}">
							<td style="padding: 5px; text-transform: capitalize;">
								{{item.status}}<br/>
								<small>{{item.action_timestamp ? moment.unix(item.action_timestamp).format("MMM. DD, YYYY hh:mm A"): 'N/A'}}</small>
	 						</td>
				      	</template>
				    </CDataTable> 
				</div>
        		</CCol> 
        	</CRow> 
    	</div>
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="$emit('showModal', false)">
                Close
            </CButton>  
        </div>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import moment from 'moment';

	export default{
		data(){
			return{
				moment
			}
		},
		props: {
			showModal: false,
			details: {
				booking_no: "",
				booking_history: []
			},
			booking_number: ""
		},  

	}
</script>